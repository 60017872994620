export default {
    list() {
        return [
            {
                clear: false,
                value: null,
                name: "utm_source",
                label: "utm_source",
            },
            {
                clear: false,
                value: null,
                name: "utm_medium",
                label: "utm_medium",
            },
            {
                clear: false,
                value: null,
                name: "utm_campaign",
                label: "utm_campaign",
            },
            {
                clear: false,
                value: null,
                name: "utm_term",
                label: "utm_term",
            },
            {
                clear: false,
                value: null,
                name: "utm_content",
                label: "utm_content",
            }
        ];
    }
};