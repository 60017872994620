<template>
  <v-card>
    <v-data-table v-model="selected" :headers="activeHeaders" :items="originalLinks" :items-per-page="15"
                  class="elevation-1" show-select>
      <template v-slot:top>
        <v-row>
          <v-toolbar flat>
            <v-toolbar-title class="ml-5">My Links</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn :input-value="!activeDefaultMenu" @click="changeTableType(false)" text small>
              Columnar view
            </v-btn>
            <v-btn :input-value="activeDefaultMenu" @click="changeTableType(true)" text small>
              Abbreviated view
            </v-btn>
            <confirm v-on:result="deleteResult" :data="confirmDelete"></confirm>
          </v-toolbar>
        </v-row>
        <v-row>
          <v-col>
            <v-row align="center" justify="start">
              <v-btn class="ml-10 mt-5" @click="batchArchive" :hidden="!showBatchActions" text>Archive links</v-btn>
              <v-btn class="ml-10 mt-5" @click="batchEdit" :hidden="!showBatchActions" text>Edit links</v-btn>
              <v-btn class="ml-10 mt-5" @click="batchDelete" :hidden="!showBatchActions" text>Delete links</v-btn>
              <v-btn class="ml-10 mt-5" @click="batchCopy" :hidden="!showBatchActions" text>Copy links</v-btn>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-select
                  v-model="linkSets.value"
                  @change="changeFilter"
                  item-text="name"
                  item-value="id"
                  :items="linkSets.items"
                  label="Links filter"
                  class="mr-10"
                  multiple
                  deletable-chips
                  small-chips
              >
                <template slot="item" slot-scope="data">
                  <v-icon v-if="data.item.archive">mdi-arrow-down-bold-box</v-icon>
                      {{ data.item.name }}
                </template>
              </v-select>
            </v-row>
            <v-row>
              <v-switch v-model="filterOr" @change="changeFilter" :label="filterOr ? filterOrLabel : filterAndLabel"
                        class="links-filter-switch" inset/>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.link="{ item }">
        <v-tooltip color="#3e4c8a" top>
          <template v-slot:activator="{ on }">
            <div v-on="on">{{ prepareShowLink(item) }}</div>
          </template>
          <span>{{ prepareShowLink(item) }}</span>
        </v-tooltip>
      </template>
      <template v-if="!activeDefaultMenu" v-slot:item.short_link="{ item }">
        <v-tooltip color="#3e4c8a" top>
          <template v-slot:activator="{ on }">
            <div v-on="on">{{ item.short_link }}</div>
          </template>
          <span>{{ item.short_link }}</span>
        </v-tooltip>
      </template>
      <template v-for="(utm, index) in utmList" v-slot:[`item.utm_fields.${utm.name}`]="{ item }">
        <v-text-field :key="index" @paste="pasteData($event, item, utm.name)" v-model="item.utm_fields[utm.name]"
                      @blur="changeLink(item)" clear-icon="mdi-close" clearable/>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="singleCopy(item.short_link)">mdi-content-copy</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        <v-icon small class="mr-2" v-if="!item.hasArchive" @click="archiveLink(item)">mdi-arrow-down-bold-box</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Link from "../../../services/link";
import Confirm from "../../interaction/Confirm";
import LinkMessage from "../../messages/link";
import SelectList from "../../../services/link-set";
import Messages from "../../messages/link-set";
import RequestRoute from "../../routes/request";
import UtmConfig from "../../../config/utm-list";
import DefaultMessage from "../../messages/default";
import HeaderConfig from "./config/list-headers";

export default {
  name: "List",
  components: {Confirm},
  data() {
    return {
      confirmDelete: {title: null, text: null, dialog: false},
      selected: [],
      headers: {abbreviated: HeaderConfig.abbreviated(), columnar: HeaderConfig.columnar(),},
      activeDefaultMenu: true,
      defaultMenuType: "abbreviated",
      columnarMenuType: "columnar",
      activeHeaders: [],
      originalLinks: [],
      linksStorage: [],
      defaultItem: {id: null, name: null, created_at: null, updated_at: null, transitions: 0, short_link: null},
      linkSets: {items: [], value: []},
      isBatchDelete: false,
      utmList: UtmConfig.list(),
      snackbar: {color: null, mode: null, snackbar: true, text: null, timeout: 6000, x: "right", y: "top"},
      filterAndLabel: "All of sets",
      filterOrLabel: "One of sets",
      filterOr: true,
    }
  },
  created() {
    Link.list().then((response) => {
      this.linksStorage = response.data.links.map((item) => {
        let linkSetsArchive = item.link_sets.filter((itemSet) => {
          return itemSet.archive;
        });

        item.hasArchive = !!linkSetsArchive.length;
        item.short_link = item.short_link || 'Waiting...';

        return item;
      });

      this.refillOriginal();
      this.fetchFilter();
      this.fetchTableType();
    });
  },
  mounted() {
    Echo.private(`userLink.${user.id}`).listen('.short-link-generated', linkPayload => {
      const link = this.originalLinks.find(link => link.id === parseInt(linkPayload.id));
      if (link !== undefined) {
        link.short_link = linkPayload.short_link;
      }
    });
  },
  computed: {
    showBatchActions() {
      return !!this.selected.length;
    },
  },
  methods: {
    fetchFilter() {
      SelectList.list().then(response => {
        this.linkSets.items = response.data;
        this.getQueryFilterValue();
        this.changeFilter();
      }).catch(() => {
        const message = Messages.failLoad;
        this.$eventHub.$emit("show-snackbar", Object.assign(this.snackbar, {color: "error", text: message}));
      });
    },
    getQueryFilterValue() {
      const query = this.$route.query;
      this.linkSets.value = Array.isArray(query.filter) ? query.filter.map(setId => parseInt(setId)) : [];
      if (typeof query.filter === "string") {
        this.linkSets.value.push(parseInt(query.filter));
      }
    },
    changeTableType(isDefault) {
      this.activeDefaultMenu = isDefault;
      this.activeHeaders = this.headers[isDefault ? this.defaultMenuType : this.columnarMenuType];
    },
    fetchTableType() {
      const query = this.$route.query;
      this.activeHeaders = this.headers[query.menuType || this.defaultMenuType] || this.headers[this.defaultMenuType];
    },
    archiveLink(item) {
      Link.archive(item.id).then((response) => {
        let item = response.data;
        let linkSetsArchive = item.link_sets.filter((itemSet) => {
          return itemSet.archive;
        });

        item.hasArchive = !!linkSetsArchive.length;

        this.linksStorage = this.linksStorage.filter(storageItem => storageItem.id !== item.id);
        this.linksStorage.push(item);
        this.changeFilter();
      });
    },
    refillOriginal() {
      let linksArray = this.linksStorage;

      this.originalLinks = linksArray.filter((item) => {
        return !item.hasArchive;
      });
    },
    deleteItem(item) {
      this.defaultItem = item;
      const confirm = {title: LinkMessage.delete.title(item.short_link), text: LinkMessage.delete.text, dialog: true};
      Object.assign(this.confirmDelete, confirm);
    },
    deleteResult(result) {
      if (result) {
        this.isBatchDelete ? this.deleteBatchItem() : this.deleteSingleItem();
      }
      this.isBatchDelete = false;
    },
    deleteBatchItem() {
      const deleteIds = this.selected.map(linkObject => linkObject.id);
      axios.post(RequestRoute.batchDelete, {ids: deleteIds})
      this.selected.forEach(linkObject => {
        const deleteIndex = this.originalLinks.indexOf(linkObject);
        this.originalLinks.splice(deleteIndex, 1);
      });
      this.selected = [];
    },
    deleteSingleItem() {
      axios.delete(`link/${this.defaultItem.id}/`);
      const deleteIndex = this.originalLinks.indexOf(this.defaultItem);
      this.originalLinks.splice(deleteIndex, 1);
    },
    editItem(item) {
      const linkId = item.id;
      this.$router.push({name: "link-update", params: {linkId}})
    },
    batchEdit() {
      this.$router.push({name: "link-batch-update", query: this.selected})
    },
    batchDelete() {
      const title = LinkMessage.delete.batchTitle(this.selected.length)
      const confirm = {title: title, text: LinkMessage.delete.batchText, dialog: true};
      Object.assign(this.confirmDelete, confirm);
      this.isBatchDelete = true;
    },
    batchCopy() {
      const linksList = this.selected.map(link => link.short_link).join("\r\n");
      this.copyToClipboard(linksList, LinkMessage.copy.successBatch, LinkMessage.copy.failBatch)
    },
    batchArchive() {
      let linksList = this.selected.map(linkObject => linkObject.id);

      axios.post(RequestRoute.batchArchive, {ids: linksList}).then((response) => {
        let archivatedItems = response.data.map((item) => {
          item.hasArchive = true;
          return item;
        });

        this.linksStorage = this.linksStorage.filter(storeItem => linksList.indexOf(storeItem.id) === -1)
        this.linksStorage.push(...archivatedItems);

        this.refillOriginal();
      });
      this.selected = [];
    },
    singleCopy(link) {
      this.copyToClipboard(link, LinkMessage.copy.success, LinkMessage.copy.fail);
    },
    copyToClipboard(copyText, successText, errorText) {
      navigator.clipboard.writeText(copyText).then(() => {
        const message = Object.assign(this.snackbar, {color: "success", text: successText});
        this.$eventHub.$emit("show-snackbar", message);
      }).catch(() => {
        const message = Object.assign(this.snackbar, {color: "error", text: errorText});
        this.$eventHub.$emit("show-snackbar", message);
      });
    },
    prepareShowLink(linkObject) {
      return this.activeDefaultMenu ? linkObject.link : linkObject.columnar_link;
    },
    changeFilter() {
      if (!this.linkSets.value.length) {
        this.refillOriginal();
        return;
      }

      this.originalLinks = this.getFilteredLinks();
    },
    getFilteredLinks() {
      return this.linksStorage.filter(this.filterOr ? this.getFilterOrHandler : this.getFilterAndHandler);
    },
    getFilterAndHandler(link) {
      return this.linkSets.value.every(setItem => link.link_sets.includes(setItem));
    },
    getFilterOrHandler(link) {
      return link.link_sets.some(setItem => this.linkSets.value.includes(setItem.id));
    },
    changeLink(link) {
      const originalLink = new URL(link.link);
      const searchParams = originalLink.searchParams;
      this.utmList.forEach(
          utm => !link.utm_fields[utm.name] ? searchParams.delete(utm.name) : searchParams.set(utm.name, link.utm_fields[utm.name])
      );

      const originalUrl = originalLink.toString();
      if (originalUrl === link.link) {
        return;
      }

      Link.update(link.id, {link: originalUrl, sets: link.link_sets}).then(response => {
        Object.assign(this.snackbar, {color: "success", text: DefaultMessage.success});
        this.$eventHub.$emit("show-snackbar", this.snackbar);
        Object.assign(link, response.data);
      }).catch(() => {
        Object.assign(this.snackbar, {color: "error", text: DefaultMessage.error})
        this.$eventHub.$emit("show-snackbar", this.snackbar);
      });
    },
    pasteData(event, linkStart, utmStart) {
      event.preventDefault();
      const readClip = navigator.clipboard.readText;
      if (typeof readClip === "undefined") {
        Object.assign(this.snackbar, {color: "error", text: DefaultMessage.error});
        this.$eventHub.$emit("show-snackbar", this.snackbar);
        return;
      }
      this.handlePasteData(linkStart, utmStart);
    },
    handlePasteData(linkStart, utmStart) {
      navigator.clipboard.readText().then(clipData => {
        let linkStartIndex = this.originalLinks.indexOf(linkStart);
        this.handlePasteRows(clipData, linkStartIndex, utmStart);
      }).catch(() => {
        Object.assign(this.snackbar, {color: "error", text: DefaultMessage.error});
        this.$eventHub.$emit("show-snackbar", this.snackbar);
      });
    },
    handlePasteRows(clipData, linkStartIndex, utmStart) {
      clipData.split("\n").forEach((row, rowIndex) => {
        const editLink = this.originalLinks[linkStartIndex + rowIndex];
        if (editLink) {
          this.handlePasteCalls(row, editLink, utmStart);
          this.changeLink(editLink);
        }
      });
    },
    handlePasteCalls(row, editLink, utmStart) {
      const utmNames = Object.keys(editLink.utm_fields);
      let utmIndex = Object.keys(editLink.utm_fields).indexOf(utmStart);
      row.split("\t").forEach((cell, cellIndex) => {
        const editUtm = utmNames[utmIndex + cellIndex];
        if (editLink.utm_fields.hasOwnProperty(editUtm)) {
          editLink.utm_fields[editUtm] = cell;
        }
      });
    },
  },
}
</script>