<template>
    <v-row justify="end">
        <v-dialog v-model="dialog" persistent max-width="290">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline">Log Out</v-card-title>
                <v-card-text>Are you sure that you want to log out?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="logout(false)">Disagree</v-btn>
                    <v-btn color="green darken-1" text @click="logout(true)">Agree</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        data()
        {
            return {
                dialog: false,
            }
        },

        methods: {
            logout(result)
            {
                this.dialog = result;

                if (result) {
                    window.location.pathname = '/logout';
                }
            },
        },
    }
</script>