import LinkRouteName from "../../routes/route-name/link";
import LinkSetRouteName from "../../routes/route-name/link-set";
import LinkBatchRouteName from "../../routes/route-name/link-batch";

const mainRoute = 'index';
const itemsMap = {};

const createLinkItem = {route: LinkRouteName.CREATE, icon: "fa-link", tooltip: "Create Link",};
const createLinkBatchItem = {route: LinkBatchRouteName.CREATE, icon: "mdi-share-all", tooltip: "Create Link Batch",};
const createLinkSetItem = {icon: "fa-boxes", click: "showLinkSetModal", tooltip: "Create Link Set",};
const cancelSaveLinkItem = {icon: "fa-times", event: "cancel-save-link", tooltip: "Cancel",};
const saveLinkItem = {icon: "fa-save", event: "submit-link", tooltip: "Save",};
const saveAndCreateNewLinkItem = {icon: "fa-share", event: "submit-link-and-new", tooltip: "Save And Create New",};

itemsMap[mainRoute] = [createLinkBatchItem, createLinkItem, createLinkSetItem,];

itemsMap[LinkRouteName.LIST] = [createLinkItem, createLinkBatchItem,];

itemsMap[LinkSetRouteName.LIST] = [createLinkSetItem,];

itemsMap[LinkRouteName.CREATE] = itemsMap[LinkRouteName.UPDATE] = [
    cancelSaveLinkItem, createLinkSetItem, saveAndCreateNewLinkItem, saveLinkItem,
];

itemsMap[LinkBatchRouteName.CREATE] = itemsMap[LinkBatchRouteName.UPDATE] = [
    cancelSaveLinkItem, createLinkSetItem, saveLinkItem,
];

export default itemsMap;