<template>
  <v-form ref="form" v-model="valid">
    <v-card>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12" sm="6">
            <set-select :link-sets="linkSets"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-textarea v-model="linksString" :rules="rules" :error-messages="errorMessages" id="inline-wrap"
                        class="mx-2" rows="10" :hint="linksStringHint" :label="textareaLabel">
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list :hidden="!showErrorsList">
              <v-subheader><span class="red--text">Please fix errors in above links</span></v-subheader>
              <v-list-item two-line v-for="(linkError, index) in this.errors" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ linkError.link }}</v-list-item-title>
                  <v-list-item-subtitle><span class="red--text">{{ linkError.error }}</span></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <confirm v-on:result="cancelResult" :data="confirmCancel"></confirm>
  </v-form>
</template>

<script>
import SetSelect from "../link-set/Select";
import SelectList from "../../../services/link-set";
import Messages from "../../messages/link-set";
import Confirm from "../../interaction/Confirm";
import LinkMessage from "../../messages/link";
import RequestRoute from "../../routes/request";
import LinkEvent from "../../event/link";
import DefaultMessage from "../../messages/default";

export default {
  components: {SetSelect, Confirm},
  name: "CreateBatchLink",
  data: () => ({
    linkSets: {value: [], items: []},
    valid: false,
    maxLinks: 1000,
    linksString: "",
    linksStringHint: "Duplicate links will be ignored",
    defaultRules: [v => !!v || "Links list must not be empty"],
    advancedRules: [
      {rule: v => !!v || LinkMessage.validation.emptyLink, abort: true},
      {
        rule: (v) => {
          try {
            return !!(new URL(v));
          } catch {
            return LinkMessage.validation.invalidLink
          }
        }, abort: false
      },
      {rule: v => !/^https?:\/\/netpeak\.me.*/i.test(v) || LinkMessage.validation.regex, abort: false}
    ],
    linksArray: [],
    errorMessages: [],
    errors: [],
    confirmCancel: {title: LinkMessage.cancel.title, text: LinkMessage.cancel.text, dialog: false},
    textareaLabel: "Insert links (each link on a new line)",
    showErrorsList: false,
  }),
  created() {
    SelectList.list().then(response => this.linkSets.items = response.data).catch(() => {
      const type = "error";
      const text = Messages.failLoad;
      const snackbar = {color: type, mode: null, snackbar: true, text: text, timeout: 6000, x: "right", y: "top"};
      this.$eventHub.$emit("show-snackbar", snackbar);
    });
    const $eventHub = this.$eventHub;
    $eventHub.$on(LinkEvent.SUBMIT, this.submit);
    $eventHub.$on(LinkEvent.CANCEL_SAVE, this.showCancelModal);
  },
  beforeDestroy() {
    this.$eventHub.$off([LinkEvent.SUBMIT, LinkEvent.CANCEL_SAVE]);
  },
  computed: {
    rules() {
      const rules = this.defaultRules;
      this.errorMessages = [];
      this.errors = [];
      this.linksString === "" || this.checkValidList();

      return rules;
    }
  },
  methods: {
    showCancelModal() {
      this.confirmCancel.dialog = true;
    },
    cancelResult(result) {
      if (result) {
        this.$router.push({name: "link"});
      }
    },
    submit() {
      const requestData = {
        links: this.linksArray.map(url => ({id: null, link: url})),
        linkSets: {value: this.linkSets.value, isClear: false}
      };

      axios.post(RequestRoute.batchCreate, requestData).then(
          () => this.$router.push({name: 'link', query: {filter: this.linkSets.value}})
      ).catch(error => {
        const message = DefaultMessage.validationError;
        const type = "error";
        const snackbar = {color: type, mode: null, snackbar: true, text: message, timeout: 6000, x: 'right', y: 'top'};
        this.$eventHub.$emit('show-snackbar', snackbar);
      });
    },
    isValidUrl(link) {
      try {
        return !!(new URL(link));
      } catch {
        return false
      }
    },
    checkValidList() {
      const linksArray = this.linksString.split(/\r?\n/);
      if (linksArray.length > this.maxLinks) {
        this.errorMessages.push(`Max ${this.maxLinks} links count in allowed`);
      }

      let errorsCount = 0;
      const uniqueLinks = [];
      linksArray.forEach(link => {
        errorsCount = this.validateLink(link, errorsCount);
        if (uniqueLinks.indexOf(link) === -1) {
          uniqueLinks.push(link);
        }
      });

      if (errorsCount) {
        this.errorMessages.push(`${errorsCount} errors in links list`);
      }

      this.linksArray = uniqueLinks;
      this.showErrorsList = !!errorsCount;
    },
    validateLink(link, errorsCount) {
      const length = this.advancedRules.length;
      for (let i = 0; i < length; i++) {
        const ruleObject = this.advancedRules[i];
        const result = ruleObject.rule(link);
        if (result !== true) {
          errorsCount++;
          this.errors.push({link: !!link ? link : "Link is empty", error: result});
        }
        if (ruleObject.abort && result !== true) {
          break;
        }
      }

      return errorsCount;
    }
  }
}
</script>