<template>
  <v-list v-if="miniVariant" dense nav>
    <v-tooltip v-for="(itemData, index) in menuItems" :key="index" right>
      <template v-slot:activator="{ on, attrs }">
        <menu-item :route="itemData.route">
          <template v-slot:icon>
            <v-icon v-bind="attrs" v-on="on" small>{{ itemData.icon }}</v-icon>
          </template>
          <template v-slot:title>
            {{ itemData.title }}
          </template>
        </menu-item>
      </template>
      <span>{{ itemData.title }}</span>
    </v-tooltip>
  </v-list>
  <v-list v-else dense nav>
    <menu-item v-for="(itemData, index) in menuItems" :route="itemData.route" :key="index">
      <template v-slot:icon>
        <v-icon small>{{ itemData.icon }}</v-icon>
      </template>
      <template v-slot:title>
        {{ itemData.title }}
      </template>
    </menu-item>
  </v-list>
</template>

<script>
import MenuItem from "./MenuItem";

export default {
  components: {MenuItem},
  props: {
    miniVariant: Boolean,
  },
  name: "MenuList",
  data: () => ({
    menuItems: [
      {
        route: {name: "link"},
        icon: "fa-link",
        title: "Link",
      },
      {
        route: {name: "link-set"},
        icon: "fa-boxes",
        title: "Link Sets",
      }
    ],
  }),
}
</script>