export default {
    list() {
        return axios.get('/link/');
    },
    edit(id) {
        return axios.get(`/link/${id}/edit/`);
    },
    update(linkId, updateData) {
        return axios.patch(`/link/${linkId}/`, updateData);
    },
    archive(linkId) {
        return axios.post(`/link/archive/${linkId}`)
    }
}