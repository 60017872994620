<template>
    <v-text-field v-model="utm.value" :name="utm.name" :label="utm.label" :disabled="utm.clear"/>
</template>

<script>
    export default {
        name: 'UtmField',
        props: ['utm'],
        watch: {
            'utm.value': function () {
                this.$emit('changeUtm', this.utm);
            }
        }
    }
</script>