<template>
  <v-form ref="form" v-model="valid">
    <v-card>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-sheet elevation="12" class="pa-12" width="50%">
            <v-col>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="10">
                  <v-list>
                    <v-subheader>Edit links: ({{ links.length }})
                      <v-btn class="ml-5" @click="showAllLinks" text small :hidden="expandLinks">Expand</v-btn>
                      <v-btn class="ml-5" @click="hideAllLinks" text small :hidden="!expandLinks">Collapse</v-btn>
                    </v-subheader>
                    <v-list-item two-line v-for="(linkObject, index) in this.showLinks" :key="index">
                      <v-text-field :value="linkObject.link" readonly filled rounded dense></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-list>
                  <v-list-item v-for="(utm, index) in this.utmList" v-bind:key="index">
                    <v-col cols="12" sm="12">
                      <utm-field :utm="utm"></utm-field>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox v-model="utm.clear" label="Clear"></v-checkbox>
                    </v-col>
                  </v-list-item>
                  <v-list-item>
                    <v-col cols="12" sm="12">
                      <v-select v-model="linkSets.value" item-text="name" item-value="id" :items="linkSets.items"
                                label="Link Sets" :disabled="clearSets" multiple deletable-chips small-chips>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0"><span>{{ item.name }}</span></v-chip>
                          <span v-if="index === 1" class="grey--text caption">
                          (+{{ linkSets.value.length - 1 }} others)
                        </span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox v-model="clearSets" label="Clear"></v-checkbox>
                    </v-col>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
          </v-sheet>
        </v-row>
      </v-container>
    </v-card>
    <confirm v-on:result="cancelResult" :data="confirmCancel"></confirm>
  </v-form>
</template>

<script>
import UtmField from "../link/UtmField";
import UtmList from "../../../config/link-set-utm";
import SelectList from "../../../services/link-set";
import Confirm from "../../interaction/Confirm";
import Messages from "../../messages/link-set";
import LinkMessage from "../../messages/link";
import RequestRoute from "../../routes/request";
import LinkEvent from "../../event/link";
import LinkSetEvent from "../../event/link-set";

export default {
  components: {UtmField, Confirm},
  name: "UpdateBatchLink",
  data: () => ({
    utmList: UtmList.list(),
    showLinks: [],
    links: [],
    valid: false,
    linkSets: {items: [], value: []},
    confirmCancel: {title: LinkMessage.cancel.title, text: LinkMessage.cancel.text, dialog: false},
    clearSets: false,
    errorSnackbar: {color: "error", mode: null, snackbar: true, text: null, timeout: 6000, x: "right", y: "top"},
    sliceLinksToShow: 3,
    expandLinks: false,
  }),
  created() {
    this.setEvents();
    try {
      this.links = this.$route.query;
      this.showLinks = this.links.slice(0, this.sliceLinksToShow);
      this.loadLinkSets();
    } catch {
      this.$router.push({name: "link"});
    }
  },
  beforeDestroy() {
    this.$eventHub.$off([LinkEvent.SUBMIT, LinkEvent.CANCEL_SAVE, LinkSetEvent.SUCCESS_SUBMIT]);
  },
  watch: {
    clearSets() {
      this.linkSets.value = this.clearSets ? [] : this.linkSets.value;
    }
  },
  methods: {
    setEvents() {
      const $eventHub = this.$eventHub;
      $eventHub.$on(LinkEvent.SUBMIT, this.submit);
      $eventHub.$on(LinkEvent.CANCEL_SAVE, this.openCancelModal);
      $eventHub.$on(LinkSetEvent.SUCCESS_SUBMIT, this.addNewLinkSet);
    },
    addNewLinkSet(response) {
      if (response.hasOwnProperty("data") && response.data.hasOwnProperty("link_set")) {
        this.linkSets.items.push(response.data["link_set"]);
      }
    },
    showAllLinks() {
      this.showLinks = this.links;
      this.expandLinks = true;
    },
    hideAllLinks() {
      this.showLinks = this.links.slice(0, this.sliceLinksToShow);
      this.expandLinks = false;
    },
    loadLinkSets() {
      SelectList.list().then(response => this.linkSets.items = response.data).catch(() => {
        this.$eventHub.$emit("show-snackbar", Object.assign(this.errorSnackbar, {message: Messages.failLoad}));
      });
    },
    submit() {
      const requestData = {links: [], linkSets: {value: this.linkSets.value, isClear: this.clearSets}};
      this.links.forEach(linkObject => {
        const url = new URL(linkObject.link);
        const searchParams = url.searchParams;
        this.utmList.forEach(utm => {
          if (utm.clear) {
            searchParams.delete(utm.name)
            return;
          }

          if (utm.value !== null) {
            searchParams.set(utm.name, utm.value);
          }
        });
        requestData.links.push({id: linkObject.id, link: url.href});
      });
      axios.patch(RequestRoute.batchUpdate, requestData).then(() => this.$router.push({name: "link"})).catch(error => {
        const message = error.response.data.message;
        this.$eventHub.$emit('show-snackbar', Object.assign(this.errorSnackbar, {message: message}));
      });
    },
    openCancelModal() {
      this.confirmCancel.dialog = true;
    },
    cancelResult(result) {
      if (result) {
        this.$router.push({name: "link"});
      }
    }
  }
}
</script>