export default {
    list()
    {
        return [
            {
                id: null,
                value: null,
                name: "utm_source",
                label: "utm_source",
            },
            {
                id: null,
                value: null,
                name: "utm_medium",
                label: "utm_medium",
            },
            {
                id: null,
                value: null,
                name: "utm_campaign",
                label: "utm_campaign",
            },
            {
                id: null,
                value: null,
                name: "utm_term",
                label: "utm_term",
            },
            {
                id: null,
                value: null,
                name: "utm_content",
                label: "utm_content",
            }
        ];
    }
}