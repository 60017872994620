<template>
  <v-row justify="center">
    <v-dialog v-model="data.dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{ data.title }}</v-card-title>
        <v-card-text>{{ data.text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogResult(false)">Disagree</v-btn>
          <v-btn color="green darken-1" text @click="dialogResult(true)">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'Confirm',
  props: ['data'],
  methods: {
     dialogResult(result)
     {
       this.data.dialog = false;
       this.$emit('result', result);
     }
  }
}
</script>