<template>
  <v-form ref="form" v-model="valid">
    <v-card>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12" sm="12">
            <v-textarea v-model="link.baseLink" color="teal" :rules="rules.nameRules" :error-messages="baseLinkErrors"
                        required dense :rows="1">
              <template v-slot:label>
                <div>
                  Link <small>(custom parameters recommended to be written in the link itself)</small>
                </div>
              </template>
            </v-textarea>
          </v-col>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6">
                <v-subheader size="2">Link Tail</v-subheader>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="link.linkSets" item-text="name" item-value="id" :items="linkList"
                  label="Link Sets" multiple deletable-chips small-chips
                >
                  <template slot="item" slot-scope="data">
                    <v-icon v-if="data.item.archive">mdi-arrow-down-bold-box</v-icon>
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-col cols="12" sm="12">
            <v-list>
              <v-list-item v-for="utm in link.utmList" v-bind:key="utm.id">
                <utm-field v-on:changeUtm="changeUtm" :utm="utm"></utm-field>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="12">
            <v-sheet elevation="12" class="pa-12">
              <v-textarea label="Your link with all parameters:" ref="totalLink" v-model="totalLink" :rows="1" outlined
                          readonly/>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <confirm v-on:result="cancelResult" :data="confirmCancel"></confirm>
    <slot></slot>
  </v-form>
</template>

<script>
import SetSelect from "../link-set/Select";
import Link from '../../../services/link';
import Confirm from "../../interaction/Confirm";
import UtmField from "./UtmField";
import Snackbar from "../../interaction/Snackbar";
import UtmList from "../../../config/utm-list";
import LinkSet from '../../../services/link-set';
import LinkMessage from '../../messages/link';
import DefaultMessage from '../../messages/default';
import LinkSetStore from "../link-set/Store";
import LinkEvent from "../../event/link";
import LinkSetEvent from "../../event/link-set";

export default {
  name: 'Store',
  components: {LinkSetStore, Snackbar, UtmField, Confirm, SetSelect},
  data: () => ({
    link: {
      id: null,
      baseLink: '',
      utmList: UtmList.list(),
      linkSets: [],
    },
    linkList: [],
    confirmCancel: {title: LinkMessage.cancel.title, text: LinkMessage.cancel.text, dialog: false},
    linkSet: {dialog: false, name: '', id: null},
    valid: true,
    totalLink: null,
    totalUrl: null,
    baseLinkErrors: null,
    rules: {
      nameRules: [
        v => !!v || LinkMessage.validation.required,
        v => !/^https?:\/\/netpeak\.me.*/i.test(v) || LinkMessage.validation.regex
      ],
    },
  }),
  beforeRouteEnter(to, from, next) {
    const linkId = to.params.linkId;
    if (!linkId) {
      next();
      return;
    }
    Link.edit(linkId).then(response => {
      const editLink = response.data;
      next(component => {
        Object.assign(component.link, {id: editLink.id, baseLink: editLink.link})
        component.link.linkSets = editLink.hasOwnProperty('link_sets') ? editLink.link_sets : [];
      });
    });
  },
  created() {
    const $eventHub = this.$eventHub;
    $eventHub.$on(LinkSetEvent.SUCCESS_SUBMIT, this.successCreateSet);
    $eventHub.$on(LinkEvent.CANCEL_SAVE, this.showCancelModal);
    $eventHub.$on(LinkEvent.SUBMIT_AND_NEW, this.submitAndNew);
    $eventHub.$on(LinkEvent.SUBMIT, this.submit);
  },
  beforeCreate() {
    LinkSet.list().then(response => {
      this.linkList = response.data;
    });
  },
  beforeDestroy() {
    this.$eventHub.$off(
        [LinkSetEvent.SUCCESS_SUBMIT, LinkEvent.CANCEL_SAVE, LinkEvent.SUBMIT_AND_NEW, LinkEvent.SUBMIT]
    );
  },
  watch: {
    'link.baseLink': function () {
      if (typeof this.link.baseLink === 'undefined') {
        return;
      }
      try {
        this.totalUrl = new URL(this.link.baseLink);
        this.baseLinkErrors = null;
        const searchParams = this.totalUrl.searchParams;
        this.link.utmList.forEach(utm => {
          utm.value = searchParams.has(utm.label) ? searchParams.get(utm.label) : utm.value;
        });

        this.buildTotalLink();
      } catch {
        this.invalidBaseUrl();
      }
    }
  },
  methods: {
    showCancelModal() {
      this.confirmCancel.dialog = true
    },
    changeUtm(utm) {
      if (typeof this.link.baseLink === 'undefined') {
        return;
      }

      if (this.totalUrl === null) {
        try {
          this.totalUrl = new URL(this.link.baseLink);
          this.baseLinkErrors = null;
        } catch {
          return this.invalidBaseUrl();
        }
      }

      const searchParams = this.totalUrl.searchParams;
      !!utm.value ? searchParams.set(utm.label, utm.value) : searchParams.delete(utm.label);

      this.buildTotalLink();
    },
    invalidBaseUrl() {
      this.baseLinkErrors = LinkMessage.validation.invalid;
      this.totalLink = null;
    },
    buildTotalLink() {
      const searchParams = this.totalUrl.searchParams;
      this.link.utmList.forEach(utm => {
        if (!!utm.value) {
          searchParams.set(utm.label, utm.value);
        }
      });

      this.totalLink = this.totalUrl.href;
    },
    cancelResult(result) {
      if (result) {
        this.$router.push({name: 'link'})
      }
    },
    submitAndNew() {
      this.submit(true);
    },
    submit(createNew = false) {
      if (!this.$refs.form.validate()) {
        return this.showMessage('error', DefaultMessage.validationError);
      }

      this.selectStoreMethod().then((response) => {
        navigator.clipboard.writeText(response.data.short_link).then(() => {
          this.showMessage('success', LinkMessage.copy.success);
        }).catch(() => {
          this.showMessage('error', LinkMessage.copy.fail);
        });
        this.successSubmit(createNew);
      }).catch(error => {
        this.failSubmit(error);
      });
    },
    selectStoreMethod() {
      const linkId = this.link.id;
      const linkSetIds = this.link.linkSets.map(linkSet => typeof linkSet === 'object' ? linkSet.id : linkSet);
      const requestData = linkId
          ? {link: {id: linkId, link: this.totalLink}, sets: linkSetIds}
          : {link: this.totalLink, sets: linkSetIds};

      return !!linkId ? axios.patch(`/link/${linkId}/`, requestData) : axios.post('/link/', requestData);
    },
    successSubmit(createNew) {
      if (createNew) {
        this.$refs.form.reset();
        this.totalUrl = null;
        this.link.id = null;
        this.$route.name === 'link-create' || this.$router.push({name: 'link-create'});
        return;
      }

      this.$router.push({name: 'link'});
    },
    failSubmit(error) {
      !error.response || this.showMessage('error', error.response.data.message);
    },
    showMessage(type, message) {
      const snackbar = {color: type, mode: null, snackbar: true, text: message, timeout: 6000, x: 'right', y: 'top'};
      this.$eventHub.$emit('show-snackbar', snackbar);
    },
    successCreateSet(response) {
      !response.data.hasOwnProperty('link_set') || this.linkList.push(response.data.link_set);
      Object.assign(this.linkSet, {id: null, name: ''});
    }
  }
}
</script>