export default {
    validation: {
        required: 'Name of set is required',
        regex: 'Name of set must contains only letters and numbers',
        length: 'Name of set must be less then 250 characters',
    },
    delete: {
        title: name => `Delete link set "${name}" ?`,
        text: 'If you are sure that you want to delete current set, please click "Agree"',
    },
    failLoad: 'Something went wrong while loading link set list. Please try again later',
}