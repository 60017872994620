export default {
    validation: {
        required: 'Base link is required',
        regex: 'Base link must not contains "netpeak.me" domain part',
        invalid: 'Base link must be valid url',
        emptyLink: 'Link must not be empty',
        invalidLink: "Link must be valid url",
    },
    cancel: {
        title: 'Cancel changes ?',
        text: 'If you are sure that you want to cancel all changes, please click "Agree"',
    },
    copy: {
        fail: 'Short link did not copy to your clipboard. You can see created short link in list of all your created links',
        failBatch: 'Selected links were not copied to your clipboard',
        success: 'Short link copied to your clipboard',
        successBatch: 'Selected links successfully copied to your clipboard',
    },
    delete: {
        title: link => `Delete link "${link}" ?`,
        text: 'If you are sure that you want to delete current item please click "Agree"',
        batchTitle(deleteCount) {
            return `You are about to remove ${deleteCount} links.`;
        },
        batchText: 'This means that when users go to short links they will see the default 404 error. Continue?',
    },
}