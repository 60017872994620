<template>
    <v-app id="inspire">
        <v-navigation-drawer app clipped :mini-variant="miniVariant" color="blue-grey" dark>
            <menu-list :mini-variant="miniVariant"></menu-list>
        </v-navigation-drawer>

      <snackbar/>

        <v-app-bar app clipped-left color="amber">
            <v-app-bar-nav-icon @click="miniVariant = !miniVariant"></v-app-bar-nav-icon>
            <v-toolbar-title>Shorter</v-toolbar-title>
            <v-spacer></v-spacer>
            <logout-button-component></logout-button-component>
        </v-app-bar>

        <v-content>
            <router-view></router-view>
        </v-content>

        <menu-button-component></menu-button-component>
        <v-footer app>
        </v-footer>
    </v-app>
</template>

<script>
    import LogoutButtonComponent from "../button/LogoutButtonComponent";
    import MenuButtonComponent from "../button/MenuButtonComponent";
    import MenuList from "../menu/menu-list/MenuList";
    import Snackbar from "../interaction/Snackbar";

    export default {
        components: {
          Snackbar,
          MenuList,
            MenuButtonComponent,
            LogoutButtonComponent,
        },

        props: {
            source: String,
        },

        data: () => ({
            miniVariant: true
        }),
    }
</script>
