<template>
  <v-card id="menu-button">
    <v-speed-dial v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left" :direction="direction"
                  :open-on-hover="false" :transition="transition" fixed
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="green darken-2" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-tooltip v-for="(config, index) in items" :key="index" left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab dark small color="#ffffff" v-on="on" v-bind="attrs"
                 @click.native="clickItem(config)">
            <v-icon color="#6c757d" small>{{ config.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ config.tooltip }}</span>
      </v-tooltip>
    </v-speed-dial>
    <link-set-store :setData="linkSetData"></link-set-store>
  </v-card>
</template>

<script>
import LinkSetStore from "../menu/link-set/Store";
import ButtonItems from "./config/menu-button-items";

export default {
  components: {LinkSetStore},
  data: () => ({
    direction: 'top',
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: 'slide-y-reverse-transition',
    fab: false,
    linkSetData: {
      set: {id: null, name: ''},
      showModal: false
    },
    items: [],
  }),
  created() {
    this.changeItemsList();
  },
  watch: {
    $route() {
      this.changeItemsList();
    }
  },
  methods: {
    changeItemsList() {
      const buttonItems = ButtonItems[this.$route.name];
      this.items = buttonItems || this.items;
    },
    clickItem(buttonItem) {
      const clickHandler = this[buttonItem.click];
      typeof clickHandler !== "function" || clickHandler();
      !buttonItem.event || this.$eventHub.$emit(buttonItem.event)
      !buttonItem.route || this.$router.push({name: buttonItem.route});
    },
    showLinkSetModal() {
      this.linkSetData.showModal = true;
    }
  }
}
</script>