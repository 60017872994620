<template>
  <v-data-table :headers="headers" :items="sets" :items-per-page="5" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>My Link Sets</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <link-set-store :set-data="formItem" v-on:submit-success="successSubmit" v-on:cancel="cancel"/>
        <confirm v-on:result="dialogResult" :data="confirmDelete"></confirm>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip top v-if="!item.archive">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small text class="mr-2" @click="cloneSet(item)">
            mdi-content-copy
          </v-icon>
        </template>
        <span>Copy</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small text class="mr-2" @click="editSet(item)">mdi-pencil</v-icon>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <v-tooltip top v-if="!item.archive">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small text class="mr-2" @click="deleteSet(item)">mdi-delete</v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    <template v-slot:item.name="{ item }">
      <v-icon v-if="item.archive">mdi-arrow-down-bold-box</v-icon>
      <a v-bind:href="'/dashboard/#/link/?filter=' + item.id">{{ item.name }}</a>
    </template>
  </v-data-table>
</template>

<script>

import linkSet from '../../../services/link-set';
import LinkSetStore from "./Store";
import Confirm from "../../interaction/Confirm";
import LinkSetMessage from '../../messages/link-set';

export default {
  components: {Confirm, LinkSetStore},
  data: () => ({
    confirmDelete: {title: null, text: null, dialog: false},
    headers: [
      {text: 'ID', align: 'start', sortable: false, value: 'id'},
      {text: 'Name', value: 'name'},
      {text: 'Created At', value: 'created_at'},
      {text: 'Transitions', value: 'transitions'},
      {text: 'Links in Set', value: 'links_count'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    sets: [],
    editIndex: -1,
    editItem: {set: {id: null, name: '', transitions: 0, links_count: 0}, showModal: false},
    defaultItem: {set: {id: null, name: '', transitions: 0, links_count: 0}, showModal: false},
    formItem: {set: {id: null, name: '', transitions: 0, links_count: 0}, showModal: false},
  }),
  created() {
    this.fetchSets();
    this.$eventHub.$on('success-submit-link-set', this.successSubmit);
  },
  beforeDestroy() {
    this.$eventHub.$off('success-submit-link-set');
  },
  methods: {
    fetchSets() {
      linkSet.list().then(response => {
        this.sets = response.data.map((linkSet) => {
          return this.mapLinkSet(linkSet);
        });
      });
    },
    mapLinkSet(linkSet) {
      let links = linkSet.links.length ? linkSet.links : [];
      linkSet.transitions = links.reduce((accumulator, currentValue) => accumulator + currentValue.transitions, 0);
      linkSet.links_count = linkSet.links.length;

      return linkSet;
    },
    cloneSet(item) {
      linkSet.copy(item.id).then((response) => {
        let linkSet = this.mapLinkSet(response.data.link_set);
        this.sets.push(linkSet);
      });
    },
    editSet(set) {
      this.editIndex = this.sets.indexOf(set);
      Object.assign(this.defaultItem, this.formItem);
      Object.assign(this.editItem.set, set)
      this.editItem.showModal = true;
      Object.assign(this.formItem, this.editItem);
    },
    deleteSet(set) {
      const config = LinkSetMessage.delete;
      Object.assign(this.confirmDelete, {title: config.title(set.name), text: config.text, set: set, dialog: true});
    },
    successSubmit(response) {
      if (!!response.data && response.data.hasOwnProperty('link_set')) {
        let createdSet = response.data.link_set;
        createdSet.views = 0;
        createdSet.links_count = 0;
        this.editIndex > -1 ? Object.assign(this.sets[this.editIndex], createdSet) : this.sets.push(createdSet);
        this.cancel();
      }
    },
    dialogResult(result) {
      if (result) {
        const set = this.confirmDelete.set;
        const index = this.sets.indexOf(set);
        this.sets.splice(index, 1);

        linkSet.delete(set.id);
      }
    },
    cancel() {
      if (this.editIndex > -1) {
        Object.assign(this.formItem, this.defaultItem);
        Object.assign(this.editItem, {id: null, name: ''});
        this.editIndex = -1;
      }
    }
  }
}
</script>