var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sets,"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("My Link Sets")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('link-set-store',{attrs:{"set-data":_vm.formItem},on:{"submit-success":_vm.successSubmit,"cancel":_vm.cancel}}),_vm._v(" "),_c('confirm',{attrs:{"data":_vm.confirmDelete},on:{"result":_vm.dialogResult}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.archive)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.cloneSet(item)}}},'v-icon',attrs,false),on),[_vm._v("\n          mdi-content-copy\n        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Copy")])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.editSet(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Edit")])]),_vm._v(" "),(!item.archive)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.deleteSet(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Delete")])]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.archive)?_c('v-icon',[_vm._v("mdi-arrow-down-bold-box")]):_vm._e(),_vm._v(" "),_c('a',{attrs:{"href":'/dashboard/#/link/?filter=' + item.id}},[_vm._v(_vm._s(item.name))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }