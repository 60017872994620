<template>
  <v-row justify="center">
    <v-dialog v-model="setData.showModal" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create link Set</span>
        </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-text-field
                    v-model="setData.set.name"
                    :rules="rules"
                    label="Set name"
                    placeholder="Enter set name"
                    outlined required
                ></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" :disabled="!valid" @click="submit">Save</v-btn>
          <v-btn color="error darken-1" @click="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Snackbar from '../../interaction/Snackbar';
import DefaultMessage from '../../messages/default';
import LinkSetMessage from '../../messages/link-set';

export default {
  name: 'LinkSetCreate',
  components: {Snackbar},
  props: {
    setData: {set: {id: null, name: ''}, showModal: false}
  },
  data: () => ({
    valid: true,
    rules: [
      v => !!v || LinkSetMessage.validation.required,
      v => /[a-z0-9а-яё]/iu.test(v) || LinkSetMessage.validation.regex,
      v => v.length <= 250 || LinkSetMessage.validation.length
    ],
    snackbar: {color: null, mode: null, snackbar: false, text: null, timeout: 6000, x: 'right', y: 'top'},
  }),
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.selectMethod().then((response) => {
        this.successResponse(response);
      }).catch((error) => {
        this.errorResponse(error)
      });
    },
    successResponse(response) {
      this.$refs.form.reset();
      this.setData.showModal = false;

      Object.assign(this.snackbar, {color: 'success', text: DefaultMessage.success, snackbar: true});
      if (!!response.data && response.data.hasOwnProperty('link_set')) {
        Object.assign(this.setData.set, response.data.link_set);
      }
      Object.assign(this.setData.set, {id: null, name: ''});
      this.$eventHub.$emit('show-snackbar', this.snackbar);
      this.$eventHub.$emit('success-submit-link-set', response);
    },
    errorResponse(error) {
      this.setData.showModal = false;

      if (!!error.response) {
        const data = error.response.data;
        Object.assign(this.snackbar, {text: data.message, color: 'error', snackbar: true});
        this.$eventHub.$emit('show-snackbar', this.snackbar);
        this.$eventHub.$emit('fail-submit-link-set', error.response);
      }
    },
    selectMethod() {
      const requestData = {name: this.setData.set.name};
      const setId = this.setData.set.id;

      return !!setId ? axios.patch(`/link-set/${setId}`, requestData) : axios.post('/link-set/', requestData);
    },
    cancel() {
      this.setData.showModal = false;
      this.$emit('cancel');
    }
  },
}
</script>