<template>
  <v-sheet elevation="12" class="pa-12">
    <v-select
        v-model="linkSets.value"
        item-text="name"
        item-value="id"
        :items="linkSets.items"
        label="Link Sets"
        multiple
        outlined
        chips
    />
    <template v-if="showCreateButton">
      <v-btn @click.stop="linkSetData.showModal = true" text small>Create new links set</v-btn>
      <link-set-create :set-data="linkSetData"/>
    </template>
  </v-sheet>
</template>

<script>
import LinkSetCreate from "../link-set/Store";

export default {
  components: {LinkSetCreate},
  name: "SetSelect",
  props: {
    linkSets: {value: [], items: []},
    showCreateButton: Boolean,
  },
  created() {
    this.$eventHub.$on("success-submit-link-set", this.successCreateSet);
  },
  data: () => ({
    linkSetData: {set: {id: null, name: ""}, showModal: false},
  }),
  beforeDestroy() {
    this.$eventHub.$off("success-submit-link-set");
  },
  methods: {
    successCreateSet(response) {
      this.linkSets.items.push(response.data.link_set);
    }
  }
}
</script>