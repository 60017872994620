export default {
    list()
    {
        return axios.get('/link-set');
    },
    copy(setId)
    {
        return axios.post(`/link-set/copy/${setId}`)
    },
    delete(setId)
    {
        return axios.delete(`/link-set/${setId}`);
    },
}