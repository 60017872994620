import VueRouter from 'vue-router'
import LinkSet from "../menu/link-set/List";
import StoreLinkSet from "../menu/link-set/Store";
import Link from '../menu/link/List';
import StoreLink from '../menu/link/Store';
import CreateBatchLink from '../menu/link-batch/Create';
import UpdateBatchLink from '../menu/link-batch/Update';
import LinkRouteName from '../routes/route-name/link';
import LinkSetRouteName from '../routes/route-name/link-set';
import LinkBatchRouteName from '../routes/route-name/link-batch';

export default new VueRouter({
    routes: [
        {path: '/', name: 'index'},
        {path: '/link', name: LinkRouteName.LIST, component: Link},
        {path: '/link/create', name: LinkRouteName.CREATE, component: StoreLink},
        {path: '/link/:linkId/edit', name: LinkRouteName.UPDATE, component: StoreLink},
        {path: '/link-set', name: LinkSetRouteName.LIST, component: LinkSet},
        {path: '/link-set/create', name: LinkSetRouteName.CREATE, component: StoreLinkSet},
        {path: '/link-batch/create', name: LinkBatchRouteName.CREATE, component: CreateBatchLink},
        {path: '/link-batch/edit', name: LinkBatchRouteName.UPDATE, component: UpdateBatchLink}
    ],
});
