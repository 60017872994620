<template>
  <v-card>
    <v-snackbar
        v-model="params.snackbar"
        :bottom="isBottom(params.y)"
        :color="params.color"
        :left="isLeft(params.x)"
        :multi-line="isMultiline(params.mode)"
        :right="isRight(params.x)"
        :timeout="params.timeout"
        :top="isTop(params.y)"
        :vertical="isVertical(params.mode)"
    >{{ params.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="params.snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: 'Snackbar',
  data: () => ({
    params: {
      snackbar: false,
      y: null,
      x: null,
      color: null,
      mode: null,
      timeout: null,
      text: null,
    }
  }),
  created()
  {
    this.$eventHub.$on('show-snackbar', this.show);
  },
  beforeDestroy()
  {
    this.$eventHub.$off('show-snackbar');
  },
  methods: {
    isMultiline(mode)
    {
      return mode === 'multi-line';
    },
    isBottom(y)
    {
      return y === 'bottom';
    },
    isLeft(x)
    {
      return x === 'left';
    },
    isRight(x)
    {
      return x === 'right';
    },
    isTop(y)
    {
      return y === 'top';
    },
    isVertical(mode)
    {
      return mode === 'vertical';
    },
    show(data)
    {
      Object.assign(this.params, data);
    }
  }
}
</script>