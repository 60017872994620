export default {
    abbreviated() {
        return [
            {text: "ID", align: 'start', sortable: false, value: "id",},
            {text: "Original Link", value: "link",},
            {text: "Short Link", value: "short_link",},
            {text: "Transitions", value: "transitions", align: "center"},
            {text: "Created At", value: "created_at",},
            {text: "Actions", value: "actions", sortable: false,},
        ];
    },
    columnar() {
        return [
            {text: "ID", align: "start", sortable: false, value: "id",},
            {text: "Original Link", value: "link",},
            {text: "Short Link", value: "short_link",},
            {text: "Transitions", value: "transitions", align: "center"},
            {text: "utm_source", value: "utm_fields.utm_source",},
            {text: "utm_campaign", value: "utm_fields.utm_campaign",},
            {text: "utm_term", value: "utm_fields.utm_term",},
            {text: "utm_content", value: "utm_fields.utm_content",},
            {text: "utm_medium", value: "utm_fields.utm_medium",},
            {text: "Created At", value: "created_at",},
            {text: "Updated At", value: "updated_at",},
            {text: "Actions", value: "actions", sortable: false,},
        ];
    },
}