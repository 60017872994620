<template>
  <v-list-item :to="route" link>
    <v-list-item-action>
      <slot name="icon"></slot>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        <slot name="title"></slot>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    route: Object,
  },
}
</script>